@import 'common/common.scss';

.witr_root {
    position: relative;

    > li {
        list-style: none;

        &:not(:last-child) {
            margin-bottom: rem(20px);
        }
    }

    &.witr_color_general {
        .witr_ip {
            color: var(--utmb-color-ws-secondary);
        }

        .witr_position {
            -webkit-text-stroke: rem(1px) var(--utmb-color-ws-secondary);
        }

        .witr_link {
            &::after {
                background-color: var(--utmb-color-ws-secondary);
            }
        }
    }

    &.witr_color_ws20k {
        .witr_ip {
            color: var(--utmb-color-category-20k);
        }

        .witr_position {
            -webkit-text-stroke: rem(1px) var(--utmb-color-category-20k);
        }

        .witr_link {
            &::after {
                background-color: var(--utmb-color-category-20k);
            }
        }
    }

    &.witr_color_ws50k {
        .witr_ip {
            color: var(--utmb-color-category-50k);
        }

        .witr_position {
            -webkit-text-stroke: rem(1px) var(--utmb-color-category-50k);
        }

        .witr_link {
            &::after {
                background-color: var(--utmb-color-category-50k);
            }
        }
    }

    &.witr_color_ws100k {
        .witr_ip {
            color: var(--utmb-color-category-100k);
        }

        .witr_position {
            -webkit-text-stroke: rem(1px) var(--utmb-color-category-100k);
        }

        .witr_link {
            &::after {
                background-color: var(--utmb-color-category-100k);
            }
        }
    }

    &.witr_color_ws100m {
        .witr_ip {
            color: var(--utmb-color-category-100m);
        }

        .witr_position {
            -webkit-text-stroke: rem(1px) var(--utmb-color-category-100m);
        }

        .witr_link {
            &::after {
                background-color: var(--utmb-color-category-100m);
            }
        }
    }

    .witr_runner_card {
        display: block;
        text-decoration: none;

        &_wrapper {
            display: flex;
        }

        .witr_thumbnail_wrapper {
            position: relative;
            min-width: rem(75px);
            height: rem(75px);

            @include bp(lg) {
                min-width: rem(150px);
                height: rem(150px);
            }
        }

        .witr_content_container {
            display: flex;
            align-items: flex-start;
            padding: rem(10px) rem(15px);

            .witr_position {
                line-height: rem(30px);
                color: var(--utmb-color-ws-primary);
                margin-right: rem(15px);

                @include bp(lg) {
                    line-height: rem(70px);
                }
            }

            .witr_ip {
                line-height: rem(22px);

                @include bp(lg) {
                    line-height: rem(32px);
                }
            }

            .witr_fullname {
                line-height: rem(28px);
                margin-bottom: rem(16px);
                text-transform: uppercase;
            }

            .witr_link {
                display: none;
                position: relative;
                text-transform: uppercase;
                text-decoration: underline;
                font-family: var(--utmb-font-futura);
                text-underline-offset: rem(10px);
                text-decoration-thickness: rem(2px) !important;
                overflow: hidden;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: rem(-10px);
                    left: 0;
                    width: 80%;
                    height: rem(2px);
                    transition: all 0.2s ease-out;
                    transform: scaleX(0);
                    transform-origin: left;
                }

                @include bp(lg) {
                    display: inline;
                }
            }
        }

        .witr_icon {
            display: flex;
            align-items: center;
            margin-right: rem(20px);
            margin-bottom: rem(20px);
            margin-left: auto;

            @include bp(lg) {
                display: none;
            }

            svg {
                width: rem(16px);
                height: rem(20px);
            }
        }
    }
}
