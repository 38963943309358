@import 'common/common.scss';

.wit_root {
    position: relative;
    background-color: var(--utmb-color-ws-primary);
    color: var(--utmb-color-white);
    padding: calc(var(--component-unhook-height-small) * 2) 0;

    .wit_bg_img {
        z-index: 0;
        width: 100%;
        height: 100%;
        opacity: 0.05;
    }

    .wit_header {
        position: relative;
        text-transform: uppercase;
        color: var(--utmb-color-ws-secondary);
        margin-bottom: var(--component-unhook-height-small);
    }

    .wit_gender_container {
        border: none;
        background-color: transparent;
        margin-bottom: rem(50px);

        &:not(:last-child) {
            border-right: rem(1px) solid var(--utmb-color-blue-light);
        }

        @include bp(lg) {
            margin-bottom: rem(25px);

            &:not(:last-child) {
                border-right: none;
            }
        }

        .wit_gender {
            text-transform: uppercase;
            text-align: center;

            @include bp(lg) {
                text-align: left;
            }
        }
    }

    .wit_gender_men {
        padding-right: 0;
    }

    .wit_gender_women {
        padding-left: 0;
    }

    .wit_top_runners_container {
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;

        @include bp(lg) {
            padding-left: var(--bs-gutter-x);
            padding-right: var(--bs-gutter-x);
        }

        .wit_cards_container {
            width: 100%;
            display: flex;
            transition: all 0.3s ease-out;

            .wit_card_wrapper {
                min-width: 100%;

                @include bp(lg) {
                    min-width: 50%;
                }
            }
        }

        .wit_cards_container {
            &:focus,
            &:hover,
            &_focused {
                .witr_hovered_card {
                    opacity: 0.35;
                    transition: opacity 0.3s;

                    &:focus,
                    &:hover,
                    &_focused {
                        opacity: 1;

                        .witr_link::after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }

        .wit_cards_container_toggled {
            transition: all 0.3s ease-out;
            transform: translateX(-100%);

            @include bp(lg) {
                transform: translateX(0);
            }
        }
    }
}
