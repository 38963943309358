@import 'common/common.scss';

.witf_root {
    position: relative;
    margin-bottom: var(--component-unhook-height);

    .witf_header {
        margin-bottom: rem(15px);
    }

    .witf_categories_button_container {
        overflow: hidden;

        .witf_icon_button {
            cursor: pointer;
            position: relative;
            border: none;
            background-color: transparent;
            opacity: 0.35;
            filter: grayscale(1);
            width: auto;
            height: rem(30px);
            margin-right: rem(25px);

            @include hover-focus {
                opacity: 1;
                filter: none;
            }

            &_active {
                opacity: 1;
                filter: none;
            }
        }
    }
}

.witf_slider {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
}
